.rate-card {
    .rate-card-grid-scroller {
        max-width: 100%;
        overflow: auto;
        flex: 1;
        display: flex;

        &::-webkit-scrollbar {
            height: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--color-light-grey-rgb), 0);
        }

        &:hover {
            &::-webkit-scrollbar-thumb {
                background-color: rgba(var(--color-light-grey-rgb), 1.0);
            }
        }
    }

    .dense-grid {
        padding: 10px !important;
    }

    .rate-card-product-column {
        flex: 1;
        flex-shrink: 0;
        white-space: nowrap;
        flex-wrap: nowrap;
    }

    .rate-card-shade-back {
        background-color: var(--color-shade);
    }
    .rate-card-bottom-border {
        border-bottom: 1px solid black;
    }

    .rate-card-rate-input {
        display: flex;
        align-items: baseline;
        padding: 10px 0px;

        input {
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: none;
            }
            -moz-appearance: textfield;
            font-size: 14px;
            padding: 0px 3px !important;
            margin-right: 15px;
            border-radius: 4px;
            
            &:focus, &:hover {
                outline: 1px solid var(--color-light-grey);
            }
        }

        &.invalid {
            opacity: 0.5;
        }

        &.customer-discount {
            input {
                margin-right: 0px;
            }
            .MuiFormControl-root {
                width: 60%;
            }
        }
    }
}