.table-row-order-wrapper {
    cursor: grab;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
        cursor: grabbing;
    }

    * {
        -webkit-user-select: none;
        user-select: none;
    }
}

.table-row-order-active {
    background-color: var(--color-light) !important;
    z-index: 999;
    position: relative;
}