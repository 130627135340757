body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-env] {
  display: none;
  visibility: hidden;
}

img {
  max-width: 100%;
  max-height: 100%;

  &.center {
    margin: auto;
  }
}
a {
  text-decoration: none;
  color: initial;
}
form {
  flex: 1;
}

.pointer {
  cursor: pointer;
}
.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.master-page-container {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .master-page-body {
    background-color: #f8f8f8;
    display: flex;
    flex: 1;
    position: relative;
    max-height: 100%;
    overflow: hidden;
 
    .master-page-outlet-container {
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        @media screen and (max-width: 899px) {
            padding: 30px 15px;
        }
        @media screen and (min-width: 900px) {
            padding: 50px;
        }
    }
  }
}

.grid-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-page {
  height: 100vh;
  width: 100vw;
}
.absolute-full-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.strike-through {
  text-decoration: line-through;
}

.color-light-grey {
  color: var(--color-light-grey);
}

.app-version {
  position: fixed;
  bottom: 0px;
  padding: 25px;
  z-index: 9999999;
  color: var(--color-light);
  opacity: 0;
  transition: opacity 0.1s linear;

  &:not(.permanent) {
    right: 0px;
  }
  &.permanent {
    pointer-events: none;
    opacity: 1;
    font-size: 12px;
    left: 0px
  }
  &.hidden {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiFormControl-root {
  &.native-look {
    margin: 0;

    .MuiInputBase-root {
      padding: 0;

      input {
        padding: 0;
      }
      fieldset {
        padding:0;
        border: none;
      }
    }
  }
}

.MuiChip-root {
  &.iconOnly {
    .MuiChip-label {
      padding-right: 0;
    }
  }
}

.MuiStepConnector-root {
  &.Mui-active, &.Mui-completed {

    .MuiStepConnector-line {
      border-color: var(--color-primary);
    }
  }
}
.MuiStepLabel-root {
  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      font-weight: normal;
    }
  }
}