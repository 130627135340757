.user-action-page-frame-container {
    position: relative;

    .background-image {
        z-index: 0;

        background-image: url('/images/login-background.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        filter: blur(8px);
    }

    .image-overlay {
        z-index: 1;
        background-color: rgba(var(--color-primary-rgb), 0.6);
    }

    .user-action-page-frame-card-wrapper {
        position: relative;
        z-index: 2;
        max-height: 100vh;
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 30px 0;
    }
}